.header {
    display: flex;
    flex-direction: row;
    align-items: center;
    height: 120px;
    padding: 0px 0px 0px 15%;
    border-bottom: 1px solid rgb(216, 216, 216);
    background-color: #ffffff;
}

.header .branding .brand_logo {
    order: 0;
    height: 50px;
    width: 50px;
    padding: 10px;
}

.header .title {
    order: 1;
    height: 25px;
    width: 110px;
    vertical-align: middle;
}

.header .navigation {
    order: 2;
    height: 45px;
    width: 750px;
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: wrap;
}

.header .navigation .menu-item {
    height: 25px;
    padding: 10px;
    vertical-align: baseline;
    justify-content: center;
}

.header .socials {
    order: 3;
    width: 15%;
}

.header .socials .social-icon {
    color: #000;
}

.header .socials .social-icons svg {
    margin-left: 20px;
}

.header .donation {
    order: 4;
    flex: auto;
}

.header .donation > Button {
    border: 1px solid rgba(0, 0, 0, 0.23);
    font-weight: bold;
    background-color: rgb(66, 179, 255);
    padding: 5px 10px
}

.head-image {
    width: auto;
}

.head-image img {
    width: 100%;
}

.header .title a {
    color: #000;
    text-decoration: none;
}

@media only screen and (max-width: 1200px) {
    .header .donation {
        display: none;
    }
}


@media only screen and (max-width: 990px) {
    .header {
        padding: 0;
    }

    .header .donation {
        display: none;
    }

    .header .branding {
        display: none;
    }

    .header .title {
        margin-left: 10px;
    }

    .header .socials {
        display: none;
    }
}