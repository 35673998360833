.contact-form-box {
    display: inline-block;
    width: 50%;
}

.contact-form-box .sub-heading {
    text-transform: uppercase;
    text-align: left;
    font-weight: bold;
}

.contact-form .field-row {
    display: flex;
}

.contact-form .field-forename {
    margin-top: 10px;
    order: 0;
    width: 50%;
}

.contact-form .field-location {
    margin-left: 10px;
    margin-top: 10px;
    width: 50%;
    order: 1;
}

.contact-form .field-subject {
    width: 100%;
    margin-top: 10px;
}

.contact-form .field-email {
    width: 100%;
    margin-top: 10px;
}

.contact-form .field-message {
    width: 100%;
    margin-top: 10px;
}

.contact-form-box .privacy-box {
    display: flex;
    align-items: flex-start;
}

.contact-form-box .privacy-checkbox {
    order: 0;
}

.contact-form-box .checkbox-text {
    font-size: 11px;
    order: 1;
    margin-top: 10px;
    text-align: left;
}

.contact-form-box .btn-checkbox {
    margin-top: 10px;
}

@media only screen and (max-width: 768px) {
    .contact-form-box {
        display: inline-block;
        width: 80%;
    }
}