.above {
    display: flex;
    align-items: flex-end;
    background:rgb(230, 230, 230)
}

.above-footer {
    padding-top: 180px;
    width: 20%;
    background: rgb(16, 42, 93);;
    -webkit-clip-path: polygon(0 0, 100% 100%, 100% 50%, 100% 100%, 0 100%, 0% 100%);
    clip-path: polygon(0 0, 100% 100%, 100% 50%, 100% 100%, 0 100%, 0% 100%);
    color: rgb(255, 255, 255);
}

.above-footer-middle {
    width: 60%;
    order: 1;
    height: 75px;
}

.above-footer-right {
    padding-top: 45px;
    width: 100%;
    background: rgb(189, 204, 231);
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 0, 100% 100%, 0 100%, 0% 50%);
    clip-path: polygon(0 100%, 100% 0, 100% 0, 100% 100%, 0 100%, 0% 50%);
    margin-left: -80%;
    margin-right: -20%;
    order: 2;
}

.above-footer-right-out {
    padding-top: 180px;
    width: 20%;
    background: rgba(203, 41, 41, 0.80);
    -webkit-clip-path: polygon(0 100%, 100% 0, 100% 0, 100% 100%, 0 100%, 0% 50%);
    clip-path: polygon(0 100%, 100% 0, 100% 0, 100% 100%, 0 100%, 0% 50%);
    order: 3;
    color: rgb(255, 255, 255);
}

.footer {
    margin-top: -1px;
    background-color: rgb(16, 42, 93);
}

.above-footer-middle .socials .social-icon {
    color: rgb(0, 0, 0);
    margin-left: 10px;
}

.footer .branding .brand-logo {
    height: 100px;
    width: 100px;
    opacity: 0.1;
}

.footer .footer-line {
    display: flex;
    justify-content: space-between;
    color: rgb(255, 255, 255);
    padding: 10px;
}

.above-footer .copyright {
    margin-bottom: 10px;
    margin-left: 20px;
    text-align: left;
}

.above-footer-right-out .important-links {
    margin-bottom: 10px;
    margin-right: 20px;
    text-align: right;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
}

.above-footer-right-out .important-links .footer-item {
    margin-left: 10px;
}

@media only screen and (max-width: 1550px) {
    .above-footer .copyright {
        font-size: 12px;
    }
}