.section-header {
    background-image: url('../images/darkblueCduBg.png');
    height: 150px;
}

.section-header h1 {
    color: #bdcce7;
    line-height: 150px;
}

.content-image img {
    width: 100%;
}

.position-right {
    justify-content: flex-end;
}

.position-left {
    justify-content: flex-start;
}

.section-content {
    display: flex;
}

.text-container {
    margin-top: 20px;
    width: 70%;
}

.image-container {
    width: 30%;
    display: flex;
    align-items: flex-start
}

.image-container img {
    width: 100%;
}

.last {
    margin-bottom: 30px;
}

@media only screen and (max-width: 768px) {
    .text-container .content {
        margin: 0 5% 0 5%;
    }
}