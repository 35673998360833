.about-me {
    width: 80%;
    margin: auto;
}

.about-me .main-heading {
    font-size: 40px;
    font-weight: bold;
}

.about-me .row .column {
    width: 50%;
    background-color: #FFFFFF;
}

.about-me .row .column .column-image {
    width: 100%;
}

.about-me .row {
    display: flex;
}

.about-me .row .column .text {
    padding: 20px;
}

.about-me .row-1 {
    background-color: rgb(16, 42, 93);
    color: rgb(255, 255, 255);
}

.about-me .row-1 p {
    text-align: center;
}

.about-me .row-1 h2 {
    text-align: center;
}

.about-me .row .full-size-img {
    max-width: 100%;
}

.about-me .row .content {
    margin: 0 5%;
    position: relative;
}

.about-me .row-4 {
    height: auto;
}

.about-me .row-4 .content {
    margin-top: 8%;
    color:rgb(255, 255, 255);
    width: 40%;
    position: absolute;
}

.row-4 h2 {
    color: #bdcce7;
}

.row h2 {
    color: #bdcce7;
}

@media only screen and (max-width: 1100px) {
    .content {
        margin: 0px;
    }

    .about-me .row {
        flex-direction: column;
    }

    .about-me .row .column {
         width: 100%;
    }
}
