.startseite {
    background-color: #E6E6E6;
}

.startseite h1 {
    font-family: Kievit;
    font-size: 50px;
}

.startseite .hero .hero-box {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    text-align: right;
}

.startseite .hero .hero-box .hero-text {
    position: absolute;
    padding: 10px;
    top: 45%;
    left: 12%;
    text-align: center;
    font-family: Kievit;
}

.startseite .hero-image {
    width: 100%;
    height: 100%;
    z-index: 0;
    position: relative;
}

.startseite .hero .hero-box .hero-btn-box {
    position: absolute;
    top: 35%;
    left: 13%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: auto;
}

.startseite .hero .hero-box .hero-btn-box .btn-primary {
    padding: 10px 40px;
    text-align: center;
    background-color: rgb(0, 0, 0) !important;
    color: rgb(255, 255, 255) !important;
    font-weight: bold;
}

.startseite .hero .hero-box .hero-btn-box .hero-btn-text {
    color: rgb(0, 0, 0);
    font-weight: bold;
    font-size: 30px;
}

.startseite .hero .hero-box .hero-text .heading {
    background-color: rgb(255, 255, 255, 1);
    padding: 0 10px 0 10px;
    text-align: left;
}

.startseite .hero .hero-box .hero-text .main {
    font-size: 100px;
    text-transform: uppercase;
}

.startseite .hero .hero-box .hero-text .top {
    font-size: 90px;
    text-transform: uppercase;
    width: 340px;
}

.startseite .hero .hero-box .hero-text .sub {
    font-size: 40px;
    margin-top: 15px;
    width: max-content;
}

.startseite .navigation {
    position: absolute;
    order: 0;
    right: 0;
    top: calc(50% - 297.5px);
    height: auto;
    width: 210px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
}

.startseite .navigation .menu-item {
    display: block;
    height: 25px;
    width: 150px;
    padding: 30px;
    text-transform: uppercase;
    line-height: 25px;
    font-family: Kievit;
    text-align: left;
    background-color: rgb(16, 42, 93);
    color: rgb(255, 255, 255);
}

.startseite .navigation .mobile-menu {
    visibility: hidden;
}

.startseite .navigation .menu-item:hover {
    background-color: rgb(198, 204, 231);
    color: rgb(0, 0, 0);
    text-decoration: none;
}

.startseite .navigation .socials {
    width: 210px;
    margin-top: 10px;
}

.startseite .navigation .socials .social-icons {
    display: flex;
    justify-content: space-evenly;
}

.startseite .navigation .socials .social-icon {
    color: rgb(16, 42, 93);
}

.startseite .navigation .socials .social-icon:hover {
    color: rgb(198, 204, 231);
}

.startseite .section-1 {
    border-top: 1px solid rgba(0, 0, 0, 0.23);
    display: flex;
    align-items: center;
}

.startseite .section-1 .newsletter {
    margin-top: -5px;
    width: 100%;
    padding: 20px;
    height: 200px;
    background-image: url('../images/darkblueCduBg.png');
    background-color: #0d629b;
}

.startseite .section-1 .newsletter .newsletter-box {
    padding: 20px 0;
    margin: auto;
    background-color: rgb(15, 76, 129);
    border: 1px solid rgb(100, 100, 100);
    width: 60%;
    height: 260px;
    overflow-y: visible;
    margin-top: -50px;
    z-index: 999;
    position: relative;
    color: #FFFFFF;
}

.startseite .section-1 .newsletter .newsletter-box .privacy-link {
    color: rgb(255, 255, 255);
    font-weight: bold;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-head {
    padding-bottom: 10px;
    font-weight: 600;
    font-size: 30px;
}

.startseite .section-1 .newsletter .newsletter-box .sub-heading {
    font-size: 12px;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-form-box {
    padding: 20px;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .newsletter-consent {
    padding: 20px;
    font-size: 11px;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .field-forename {
    margin-right: 20px;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .field-email {
    width: 50%;
    background-color: #FFFFFF;
    border-radius: 5px;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-button {
    margin-top: 30px;
}

.startseite .section-1 .newsletter .newsletter-box .newsletter-button a {
    height: 50px;
    width: auto;
    color: rgb(255, 255, 255);
    background-color: rgb(203, 41, 41);
    border-radius: 5px;
    padding: 20px;
    border: none;
}

h1 {
    font-size: 40px;
    font-weight: bold;
}

.startseite .section-2 .news .main-heading {
    margin-top: 60px;
}

.startseite .section-2 .news .news-content {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 20px;
}

.startseite .section-2 .news .news-content .news-box {
    width: 20%;
    height: auto;
    background-color: rgb(255, 255, 255);
    border: 1px solid rgb(200, 200, 200);
    box-shadow: 2px 5px 6px rgba(200, 200, 200, 0.3);
    padding: 20px;
    margin-left: 20px;
    overflow: visible;
}

.startseite .section-2 .news .news-content .news-box .image-box .news-image {
    max-width: 100%;
    max-height: 100%;
}

.startseite .section-2 .news .news-content .news-box .news-text {
    height: 250px;
    text-align: left;
    overflow: hidden;
}

.startseite .section-2 .news .news-content .news-box .btn-news {
    margin-top: 10px;
    float: left;
}

.startseite .section-2 .news .news-content .news-box .news-text h2 {
    text-align: left;
}

.startseite .section-2 .news .left {
    order: 0;
}

.startseite .section-2 .news .middle {
    order: 1;
}

.startseite .section-2 .news .right {
    order: 2;
}

.startseite .section-3 .work {
    height: auto;
    background-image: url('../images/workBg.png');
    background-repeat: no-repeat;
    background-size: 100%, cover;
    text-align: center;
}

.startseite .section-3 .work .main-heading h1{
    color:rgb(189, 204, 231);
    padding: 20px;
}

.startseite .section-3 .work .work-container {
    display: flex;
    justify-content: center;
    align-items: flex-start;
}

.startseite .section-3 .work .work-container .work-button-left {
    order: 0;
    background-color: #E6E6E6;
    padding: 20px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: 12%;
}

.startseite .section-3 .work .work-container .work-button-left .dir-icon {
    height: 20px;
    width: 20px;
    margin-top: -5px;
    margin-left: -1px;
}

.startseite .section-3 .work .work-container .work-button-right .dir-icon {
    height: 20px;
    width: 20px;
    margin-top: -5px;
    margin-left: -3px;
}

.startseite .section-3 .work .work-container .work-button-right {
    order: 2;
    background-color: #E6E6E6;
    padding: 20px;
    height: 10px;
    width: 10px;
    border-radius: 50%;
    margin-top: 12%;
    margin-left: 40px;
}

.startseite .section-3 .work .work-image-box {
    height: 522.2px;
    width: 293.3px;
    margin: 4px 4px;
    transition: clip-path 1s;
}

.startseite .section-3 .work .work-image-box-outer {
    height: 530.2px;
    width: 301.3px;
    background-color: #FFFFFF;
    transition: clip-path 1s;
    margin-left: 40px;
    margin-bottom: 40px;
}

.startseite .section-3 .work .work-container:hover .right{
    clip-path: polygon(0 0, 100% 5%, 100% 95%, 0% 100%);
}

.startseite .section-3 .work .work-container:hover .left {
    clip-path: polygon(0 5%, 100% 0, 100% 100%, 0 95%);
}

.startseite .section-3 .work .work-image-box .work-image {
    width: 100%;
    height: 100%;
}

.startseite .section-3 .work .work-image-box .work-text {
    vertical-align: middle;
}

.startseite .section-4 .countdown {
    height: 656px;
    background-color: #FFFFFF;
    background-image: linear-gradient(to top, #E6E6E6 5%, transparent 30%), url('../images/countdownBg.png');
    background-size: 100%, cover;
    background-repeat: no-repeat;
}

.startseite .section-4 .countdown .clock {
    display: flex;
    justify-content: center;
}

.startseite .section-4 .countdown .clock .clock-number {
    margin-top: 5vh;
    margin-left: 10px;
    width: 10%;
    height: 50px;
    background-color: #ffffffa8;
    border: 1px solid #E6E6E6;
    font-weight: bold;
    order: 0;
}

.startseite .section-4 .countdown .clock .clock-number .sub-heading {
    font-weight: 400;
}

.startseite .section-4 .countdown-text {
    padding-top: 12%;
    font-size: 50px;
    text-transform: uppercase;
    font-family: Kievit;
}

.startseite .section-5 .contact .contact-image-box {
    max-width: 100%;
    max-height: 100%;
    position: relative;
    text-align: right;
}

.startseite .section-5 .contact .contact-image-box .contact-image {
    max-width: 100%;
    max-height: 100%;
    z-index: 0;
    position: relative;
}

.startseite .section-5 .contact .contact-image-box .contact-text {
    position: absolute;
    padding: 10px;
    top: 70%;
    left: 4%;
    text-align: center;
    font-family: Kievit;
}

.startseite .section-5 .contact .contact-image-box .contact-text .main-heading {
    font-size: 100px;
    text-transform: uppercase;
    background-color: rgb(255, 255, 255, 1);
    padding: 0 10px 0 10px;
    margin-top: 10px;
}

.startseite .section-5 .contact .contact-image-box .contact-text .sub-heading {
    font-size: 40px;
    background-color: rgb(255, 255, 255, 1);
    padding: 0 10px 0 10px;
    text-align: left;
    width: max-content;
}

.btn-primary {
    border: 1px solid #102a5d !important;
    color: #102a5d !important;
    background-color:#bdcce7 !important;
    border-radius: 4px;
    text-transform: uppercase;
    padding: 6px 8px;
    text-decoration: none !important;
}

@media only screen and (max-width: 1550px) {

    .startseite .hero .hero-box .hero-btn-box {
        top: 30%;
    }

    .startseite .hero .hero-box .hero-text .top {
        width: auto;
    }

    .startseite .hero .hero-box .hero-text .main {
        font-size: 70px;
    }

    .startseite .hero .hero-box .hero-text .sub {
        font-size: 20px;
    }

    .startseite .section-5 .contact .contact-image-box .contact-text .main-heading {
        font-size: 70px;
    }
    
    .startseite .section-5 .contact .contact-image-box .contact-text .sub-heading {
        font-size: 20px;
    }
}

@media only screen and (max-width: 1150px) {

    .startseite .hero .hero-box .hero-btn-box {
        display: none;
    }

    .startseite .hero .hero-box .hero-text {
        top: 35%;
    }

    .startseite .hero .hero-box .hero-text .main {
        font-size: 70px;
    }

    .startseite .hero .hero-box .hero-text .sub {
        font-size: 20px;
    }

    .startseite .navigation .socials {
        display: none;
    }

    .startseite .section-5 .contact .contact-image-box .contact-text .main-heading {
        font-size: 70px;
    }
    
    .startseite .section-5 .contact .contact-image-box .contact-text .sub-heading {
        font-size: 20px;
    }
}

@media only screen and (max-width: 950px) {
    .startseite .section-1 {
        border-top: 1px solid rgba(0, 0, 0, 0.23);
        display: flex;
        align-items: center;
    }
    
    .startseite .section-1 .newsletter {
        height: auto;
    }
    
    .startseite .section-1 .newsletter .newsletter-box {
        width: 90%;
        height: auto;
        margin-top: 0px;
    }
    
    .startseite .section-1 .newsletter .newsletter-box .newsletter-head {
        font-size: 15px;
    }
    
    .startseite .section-1 .newsletter .newsletter-box .sub-heading {
        font-size: 11px;
    }
    
    .startseite .section-1 .newsletter .newsletter-box .newsletter-form-box {
        flex-direction: row;
        padding: 20px;
    }
    
    .startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .newsletter-consent {
        padding: 20px;
        font-size: 11px;
    }
    
    .startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .field-forename {
        width: auto;
    }
    
    .startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .field-email {
        width: auto;
    }

    .startseite h1 {
        font-size: 25px;
    }

    .startseite .section-5 .contact .contact-image-box .contact-text .main-heading {
        font-size: 50px;
    }
    
    .startseite .section-5 .contact .contact-image-box .contact-text .sub-heading {
        font-size: 35px;
    }
}

@media only screen and (max-width: 768px) {
    .startseite .hero .hero-box .hero-text {
        position: absolute;
        padding: 10px;
        top: 30%;
        left: 5%;
        text-align: center;
        font-family: Kievit;
    }

    .startseite .hero .hero-box .hero-text .main {
        font-size: 25px;
        text-transform: uppercase;
    }
    
    .startseite .hero .hero-box .hero-text .top {
        font-size: 20px;
        text-transform: uppercase;
        width: auto;
    }
    
    .startseite .hero .hero-box .hero-text .sub {
        font-size: 10px;
        margin-top: 15px;
        width: max-content;
    }

    .startseite .hero .hero-box .hero-btn-box {
        visibility: hidden;
    }

    .startseite .navigation .mobile-menu {
        visibility: visible;
        width: 40px;
        height: 40px;
        background-color: rgb(16, 42, 93);
        border-radius: 10%;
        margin-top: 20px;
        margin-left: 150px;
    }

    .startseite .navigation .mobile-menu .menu-icon {
        color: #FFFFFF;
        height: 30px;
        width: 30px;
        padding: 5px;
    }

    .startseite .navigation {
        top: 0;
    }

    .startseite .navigation .mobile-menu:hover {
        background-color: rgb(198, 204, 231);
    }
    
    .startseite .navigation .menu-item {
        display: none;
        box-sizing: content-box;
        padding: 5px;
        text-align: center;
        width: 100px;
        margin-left: 100px;
    }
    
    .startseite .navigation .menu-item:hover {
        background-color: rgb(198, 204, 231);
        color: rgb(0, 0, 0);
        text-decoration: none;
    }

    .startseite .navigation .socials {
        visibility: hidden;
    }

    .startseite .section-2 .news .main-heading {
        margin-top: 20px;
    }
    
    .startseite .section-2 .news .news-content {
        flex-direction: column;
    }
    
    .startseite .section-2 .news .news-content .news-box {
        margin-top: 20px;
        width: 80%;
        height: auto;
    }
    
    .startseite .section-2 .news .news-content .news-box .news-text {
        height: 250px;
        text-align: left;
        overflow: hidden;
    }

    .startseite .section-3 .work {
        height: auto;
        background-image: url('../images/workBg.png');
        background-repeat: no-repeat;
        background-size: 100%, cover;
        text-align: center;
    }
    
    .startseite .section-3 .work .main-heading h1{
        color:rgb(189, 204, 231);
        padding: 20px;
    }
    
    .startseite .section-3 .work .work-container .work-button-left {
        visibility: hidden;
    }

    .startseite .section-3 .work .work-container .work-button-right {
        visibility: hidden;
    }
    
    .startseite .section-3 .work .work-image-box {
        width: auto;
        height: auto;
        margin: 4px 4px;
    }

    .startseite .section-3 .work .work-image-box img {
        width: 100%;
        height: auto;
    }

    .startseite .section-3 .work .work-image-box-outer {
        width: 50%;
        height: auto;
        margin-left: 20px;
        margin-bottom: 10px;
    }
    
    .startseite .section-3 .work .work-container:hover .right{
        clip-path: none;
    }
    
    .startseite .section-3 .work .work-container:hover .left {
        clip-path: none;
    }
    
    .startseite .section-3 .work .work-image-box .work-image {
        width: 100%;
        height: auto;
    }
    
    .startseite .section-3 .work .work-image-box .work-text {
        vertical-align: middle;
    }

    .startseite .section-4 .countdown .clock {
        flex-direction: column;
        align-items: center;
    }

    .startseite .section-4 .countdown .clock .clock-number {
        width: 30%;
        height: 50px;
    }

    .startseite .section-5 .contact .contact-image-box .contact-text {
        top: 60%;
        text-align: center;
        font-family: Kievit;
    }
    
    .startseite .section-5 .contact .contact-image-box .contact-text .main-heading {
        font-size: 40px;
    }
    
    .startseite .section-5 .contact .contact-image-box .contact-text .sub-heading {
        font-size: 25px;
    }
}

@media only screen and (max-width: 620px) {
    .startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .field-forename {
        margin: 0;
        width: 80%;
    }
    .startseite .section-1 .newsletter .newsletter-box .newsletter-form-box .field-email {
        margin: 10px 0 0 0;
        width: 80%;
    }
}

@media only screen and (max-width: 550px) { 
    .startseite .section-5 .contact .contact-image-box .contact-text .main-heading {
        font-size: 25px;
    }
    
    .startseite .section-5 .contact .contact-image-box .contact-text .sub-heading {
        font-size: 15px;
    }
}