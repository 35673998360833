.contact-wrapper {
    width: 70%;
    margin: auto;
}

.contact-wrapper .row {
    display: flex;
    justify-content: flex-start;
    margin-top: 10px;
}

.contact-wrapper .row .column-image {
    width: 100%;
    object-fit: cover;
}

.contact-wrapper .personal {
    flex-wrap: wrap;
}

.contact-wrapper .team-wrapper .row .column-image {
    max-height: 200px;
    max-width: 150px;
    border-radius: 5px;
}

.contact-wrapper .row .frame {
    padding: 10px;
    margin-left: 20px;
    background-color: #FFFFFF;
    display: flex;
    width: 100%;
    overflow: hidden;
}

.contact-wrapper .team-wrapper .row .frame {
    padding: 10px;
    margin: 10px;
    background-color: #FFFFFF;
    display: flex;
    width: 140px;
    border-radius: 5px;
    box-shadow: #000;
}

.contact-wrapper .images-wrapper .row .frame .appearing-div {
    width: 300px;
    text-align: left;
    margin-left: 20px;
}

.contact-wrapper .images-wrapper .row .appearing-div .btn-primary {
    padding: 10px 50px;
}

.contact-wrapper .images-wrapper .row .appearing-div p {
    width: 250px;
} 

.contact-wrapper .images-wrapper .row .frame .hide {
    display: none;
}

.contact-wrapper .images-wrapper .row .frame:hover {
    padding: 10px 300px 10px 10px;
    background-color: #FFF;
}

.contact-wrapper .images-wrapper .usage-text {
    text-align: left;
    margin-left: 20px;
    font-size: 11px;
}

.contact-wrapper .images-wrapper .usage-text .content {
    margin: 0;
}

.contact-wrapper .images-wrapper .press-contact {
    text-align: left;
    margin-left: 20px;
    font-weight: bold;
}

.calendly-wrapper {
    background-color: rgb(16, 42, 93);
}

.calendly-wrapper .calendly-heading h1 {
    padding-top: 20px;
    color: rgb(189, 204, 231);
}

.calendly-wrapper .calendly-footer h1 {
    color: rgb(189, 204, 231);
}

.calendly-inline-widget {
    min-width: 320px;
    height: 670px;
}

h1 {
    font-family: Kievit;
    text-transform: uppercase;
}

.other-events {
    width: auto;
}

.other-events img {
    max-width: 90%;
}

@media only screen and (max-width: 768px) {

}