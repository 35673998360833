

.paypal-body {
    width: 100%;
    text-align: center;
}

.divider-image {
    width: 100%;
}

.divider-image img {
    width: 100%;
}

.example-amounts {
    width: 120px;
    margin-right: 10px;
}

.example-amounts input {
    cursor: pointer;
}

.example-amounts:hover {
    background-color: #bdcce7;
    color: #000 !important;
}

.donate-form {
    margin: 0 15% 0 15% !important;
    padding: 10px !important;
    display: flex !important;
    flex-direction: row !important;
    justify-content: center !important;
}

.amount-label {
    transform-origin: unset !important;
}

.amount-container {
    padding: 10px !important;
    justify-content: center;
    align-content: center;
    width: 380px;
}

@media only screen and (max-width: 768px) {
    .donate-text-body .content {
        margin: 0px;
    }

    .donate-form {
        margin: 10px 0 0 0 !important;
    }

    .amount-container {
        width: calc(100%-20px);
    }
}